import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import "./aboutUsTemplate.scss";

const AboutUsTemplate = () => {
  const data = useStaticQuery(graphql`
    query QUERY_ABOUT_US {
      allContentfulPaginaNosotros {
        nodes {
          mainTitle
          mainText {
            raw
          }
          missionImage {
            file {
              url
            }
          }
          missionText {
            raw
          }
          missionTitle
          valuesTitle
          valuesText {
            raw
          }
          visionImage {
            file {
              url
            }
          }
          visionTitle
          visionText {
            raw
          }
        }
      }
    }
  `);

  const info = data.allContentfulPaginaNosotros.nodes[0];

  const { 
    mainTitle,
    mainText,
    missionTitle,
    missionText,
    missionImage,
    visionTitle,
    visionText,
    visionImage,
    valuesTitle,
    valuesText
  } = info;

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span>{text}</span>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return <p className="t-about-us__desc">{children}</p>
      }
    }
  }

  return (
    <div className="t-about-us">
      <h1 className="t-about-us__title">{mainTitle}</h1>
      {documentToReactComponents(JSON.parse(mainText.raw), options)}
        <h2 className="t-about-us__subtitle">{missionTitle}</h2>
        <div className="t-about-us__mission-content">
          <div className="t-about-us__mission-content__text">
            {documentToReactComponents(JSON.parse(missionText.raw), options)}
          </div>
          <img 
            className="t-about-us__side-image"
            src={missionImage.file.url}
            alt="Imagen misión"
          />
        </div>
        <h2 className="t-about-us__subtitle">{visionTitle}</h2>
        <div className="t-about-us__vision-content">
          <div className="t-about-us__vision-content__text">
            {documentToReactComponents(JSON.parse(visionText.raw), options)}
          </div>
          <img 
            className="t-about-us__side-image"
            src={visionImage.file.url}
            alt="Imagen visión"
          />
        </div>
        <h2 className="t-about-us__subtitle">{valuesTitle}</h2>
        {documentToReactComponents(JSON.parse(valuesText.raw), options)}
    </div>
  )
}

export default AboutUsTemplate;
import React from "react";
import Layout from "../components/organisms/layout/layout";
import AboutUsTemplate from "../components/templates/about-us-template/aboutUsTemplate";

const AboutUs = () => {
  return (
    <Layout title="Dicar | Nosotros">
      <AboutUsTemplate />
    </Layout>
  )
}

export default AboutUs;